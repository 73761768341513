import React, { useContext, useState, useRef } from "react";
import { useIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Context from "./context";
import SubmitButton from "./submit-button";
import SuccessModal from "./success-modal";

const CareersForm = () => {
  const { data } = useContext(Context);
  const intl = useIntl();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const formEl = useRef(null);
  const recaptchaEl = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSuccess("");
    setError("");
    setIsButtonLoading(true);
    recaptchaEl.current.execute();
  };

  const handleChange = async () => {
    try {
      const response = await fetch("https://formspree.io/f/myylvpan", {
        method: "POST",
        body: new FormData(formEl.current),
        headers: {
          Accept: "application/json",
        },
      });

      setIsButtonLoading(false);

      if (response.ok) {
        formEl.current.reset();
        setSuccess(intl.formatMessage({ id: "form.success" }));
      } else {
        setError(intl.formatMessage({ id: "form.error" }));
      }
    } catch (error) {
      console.error(error);
      setError(intl.formatMessage({ id: "form.error" }));
    }
  };

  return (
    <>
      <form ref={formEl} className="form" onSubmit={handleSubmit}>
        <input
          type="hidden"
          name="subject"
          value="Nouvelle candidature depuis ace-hotel.com"
        />
        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <input type="hidden" name="website" value="ace-hotel.com" />
        <div className="form-field">
          <input
            type="text"
            name="firstName"
            id="firstName"
            placeholder={intl.formatMessage({ id: "form.first-name" })}
            required
          />
          <label htmlFor="firstName">
            {intl.formatMessage({ id: "form.first-name" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="text"
            name="lastName"
            id="lastName"
            placeholder={intl.formatMessage({ id: "form.name" })}
            required
          />
          <label htmlFor="lastName">
            {intl.formatMessage({ id: "form.name" })}
          </label>
        </div>
        <div className="form-field">
          <input
            type="email"
            name="email"
            id="email"
            placeholder={intl.formatMessage({ id: "form.email" })}
            required
          />
          <label htmlFor="email">
            {intl.formatMessage({ id: "form.email" })}
          </label>
        </div>
        <div className="form-field">
          <select name="hotel" id="hotel" required>
            <option value={intl.formatMessage({ id: "form.all-of-france" })}>
              {intl.formatMessage({ id: "form.all-of-france" })}
            </option>
            {data.allContentfulHotel.nodes.map((node, i) => (
              <option key={i} value={node.title}>
                {node.title}
              </option>
            ))}
          </select>
          <label htmlFor="hotel">
            {intl.formatMessage({ id: "form.hotel" })}
          </label>
        </div>
        <div className="form-field">
          <textarea
            type="textarea"
            name="message"
            id="message"
            placeholder={intl.formatMessage({ id: "form.message" })}
            rows="5"
            cols="40"
            required
          />
          <label htmlFor="message">
            {intl.formatMessage({ id: "form.message" })}
          </label>
        </div>
        <div className="form-file">
          <label htmlFor="file">
            {intl.formatMessage({ id: "form.add-file" })}
          </label>
          <input
            type="file"
            name="file"
            id="file"
            accept=".txt, .pdf, .doc, .docx, .odt"
          />
        </div>
        <div className="form-checkbox">
          <input type="checkbox" name="gdpr" id="gdpr" required />
          <label htmlFor="gdpr">
            {intl.formatMessage({ id: "form.gdpr" })}
          </label>
        </div>
        <div className="d-flex justify-content-end mt-6">
          <SubmitButton isLoading={isButtonLoading}>
            {intl.formatMessage({ id: "form.send" })}
          </SubmitButton>
        </div>
        <ReCAPTCHA
          ref={recaptchaEl}
          size="invisible"
          sitekey="6LfktEscAAAAANbniH0HZAIFaWyjBlMaaVOJkc8G"
          onChange={handleChange}
        />
      </form>
      <SuccessModal success={success} />
      {error && <p className="form-error">{error}</p>}
      {data.contentfulPage.seo_content && (
        <aside className="mt-12 mt-md-20">
          {renderRichText(data.contentfulPage.seo_content, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="small">{children}</p>
              ),
            },
          })}
        </aside>
      )}
    </>
  );
};

export default CareersForm;
